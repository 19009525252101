.treatment {
    width: 100%;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;

    .treatment-name {
        width: 100%;
    }

    .treatment-exercise {
        width: 100%;
        margin-top: 1em;
    
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: center;
    
        text-align: center;

        .exercise {
            width: 100%;

            .exercise-data {
                margin-top: 1em;
            }
        }

        .completion {
            width: 100%;
        }
    }

}

.card {
    margin-top: 1em;
}

.no-treatment {
    margin-top: 1em;
    width: 100%;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}